export default {
    setPizzaMenu(state, payload) {
        state.pizzaMenu = payload;
    },
    setFrittiMenu(state, payload) {
        state.frittiMenu = payload;
    },
    setBeverageMenu(state, payload) {
        state.beverageMenu = payload;
    }
}